import React from 'react'
import { graphql } from 'gatsby'

import SEOPage from '../components/SEO/Page'
import Layout from '../components/layout'

class ContactPage extends React.Component {
  render() {
    const { location, data } = this.props

    const contactContent = data.prismicContact.data
    const settingsContent = data.prismicSettings.data

    return (
      <Layout location={location}>
        <SEOPage
          title={contactContent.title && contactContent.title}
          location={location}
        />
        <div className="mt-16 lg:mt-35 mb-12 lg:mb-48 px-3 lg:px-14">
          <div className="lg:flex lg:-mx-2">
            {contactContent.title && (
              <div className="lg:w-1/2 lg:px-2 mb-12 lg:mb-0">
                <h1 className="lg:w-4/5 tracking-4xl text-4xl font-light leading-tight text-grey">
                  {contactContent.title}
                </h1>
              </div>
            )}
            <div className="lg:w-1/2 lg:px-2 tracking-lg text-lg leading-lg">
              {contactContent.pre_text.html && (
                <div
                  className="rte rte--dark text-2xl tracking-2xl mb-12"
                  dangerouslySetInnerHTML={{
                    __html: contactContent.pre_text.html,
                  }}
                />
              )}

              <div className="text-2xl lg:text-4xl tracking-2xl lg:tracking-4xl font-light leading-tight mb-12">
                {settingsContent.phone_number && (
                  <div>
                    <span className="inline-block w-12">p. </span>
                    <a
                      className="link inline underline lg:no-underline"
                      href={`tel:${settingsContent.phone_number}`}
                    >
                      +64 {settingsContent.phone_number}
                    </a>
                  </div>
                )}
                {settingsContent.fax_number && (
                  <div>
                    <span className="inline-block w-12">f. </span>
                    <a
                      className="link inline underline lg:no-underline"
                      href={`tel:${settingsContent.fax_number}`}
                    >
                      +64 {settingsContent.fax_number}
                    </a>
                  </div>
                )}
                {settingsContent.email_address && (
                  <div className="flex">
                    <span className="inline-block w-12 flex-no-shrink">
                      e.{' '}
                    </span>
                    <a
                      className="break-all link inline underline"
                      href={`mailto:${settingsContent.email_address}`}
                    >
                      {settingsContent.email_address}
                    </a>
                  </div>
                )}
              </div>

              <div className="text-2xl tracking-2xl">
                {contactContent.text.html && (
                  <div
                    className="mb-24"
                    dangerouslySetInnerHTML={{
                      __html: contactContent.text.html,
                    }}
                  />
                )}
                {settingsContent.physical_address_title && (
                  <p className="font-medium lg:mb-0">
                    {settingsContent.physical_address_title}
                  </p>
                )}
                {data.prismicDirections.data.map_link.url && (
                  <a
                    className="link inline-block"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={data.prismicDirections.data.map_link.url}
                  >
                    <div
                      className="mb-8"
                      dangerouslySetInnerHTML={{
                        __html: settingsContent.physical_address.html,
                      }}
                    />
                  </a>
                )}

                {settingsContent.postal_address_title && (
                  <p className="font-medium lg:mb-0">
                    {settingsContent.postal_address_title}
                  </p>
                )}
                {settingsContent.postal_address.html && (
                  <div
                    className="mb-8"
                    dangerouslySetInnerHTML={{
                      __html: settingsContent.postal_address.html,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const pageContactQuery = graphql`
  query {
    prismicContact {
      uid
      data {
        title
        pre_text {
          html
        }
        text {
          html
        }
      }
    }
    prismicSettings {
      data {
        phone_number
        fax_number
        email_address
        physical_address_title
        physical_address {
          html
        }
        postal_address_title
        postal_address {
          html
        }
      }
    }
    prismicDirections {
      data {
        map_link {
          url
        }
      }
    }
  }
`

export default ContactPage
